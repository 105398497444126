import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag-next'

const app = createApp(App)
app.use(VueGtag, {
  property: { id: 'G-CL7EM19N6H' }
}, router)

app.use(store).use(router).mount('#app')
