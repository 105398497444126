import axios from 'axios'

export default {
  async $api (url, method, data, headers) {
    const res = await axios({
      method,
      url,
      data,
      headers
    }).catch(e => {
      console.log(e)
    })

    return res
  }
}
