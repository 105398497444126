import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/404/NotFound'

// -------------
// scroll 관련
// -------------
// 1. 위치 파악하기
const getPosY = (page) => {
  if (!page) return

  let posY = false
  try {
    posY = document.querySelector('#' + page).offsetTop
  } catch (e) {
    posY = false
  }
  return posY
}

// 2. 이동 애니메이션
const pageCheck = (page) => {
  if (page === undefined) return
  // window.scrollTo(0, 0)

  // const scrollEle = document.documentElement || window.scrollingElement
  const targetY = getPosY(page)

  window.scrollTo({
    top: targetY,
    behavior: 'smooth'
  })
}

// 3. 스크롤 계산 최적화
let timer = null
const scrollOptimization = (dataList, depth) => {
  try {
    if (timer != null) {
      clearTimeout(timer)
      timer = null
    }

    timer = setTimeout(function () {
      scrollMenuCheck(dataList, depth)
      clearTimeout(timer)
      timer = null
    }, 300)
  } catch (e) { }

  // 추가(추후) : 짧게 여러번 스크롤 할경우 리턴하기
}

// 4. 페이지 인식
const scrollMenuCheck = (pageList, depth) => {
  if (!pageList || pageList.length <= 1) return
  try {
    const posY = window.scrollY
    const posArr = []
    const eleArr = []
    pageList.forEach((element, index) => {
      posArr.push(document.querySelector('#' + element).offsetTop)
      if (depth === 2) {
        eleArr.push(document.querySelector('#lnb li.on ul li:nth-child(' + (index + 1) + ')'))
      } else if (depth === 3) {
        eleArr.push(document.querySelector('#lnb li.on ul li.on ul li:nth-child(' + (index + 1) + ')'))
      }
    })

    eleArr.forEach((ele, n) => {
      ele.classList.remove('on')
    })

    posArr.forEach((data, index) => {
      if (index === 0) {
        if (posArr.length > 1) {
          if ((posY >= posArr[index] && posY < posArr[index + 1])) {
            eleArr[0].classList.add('on')
          }
        } else {
          eleArr[0].classList.add('on')
        }
      } else if (index > 0 && index < posArr.length - 1) {
        if (posY >= posArr[index] && posY < posArr[index + 1]) {
          eleArr[index].classList.add('on')
        }
      } else {
        if (posY >= posArr[index]) {
          eleArr[index].classList.add('on')
        }
      }
    })
  } catch (e) { }
}

// -------------
// 라우터 설정
// -------------
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  // 404 페이지 없음
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound
  },

  // 온라인
  {
    path: '/guide/online/start',
    name: 'OnlineStart',
    component: () => import(/* webpackChunkName: "onlineStart" */ '../views/online/Start.vue')
  },

  {
    path: '/guide/online/easypay/:page',
    name: 'OnlineEasyPay',
    component: () => import(/* webpackChunkName: "onlineEasypay" */ '../views/online/EasyPay.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['flow', 'reserve', 'return', 'approval', 'cancel'], 3)
      })
    }
  },

  {
    path: '/guide/online/autopay/:page',
    name: 'OnlineAutoPay',
    component: () => import(/* webpackChunkName: "onlineAutopay" */ '../views/online/AutoPay.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['flow', 'reserve', 'return', 'info', 'payment', 'cancel'], 3)
      })
    }
  },

  {
    path: '/guide/online/etc/:page',
    name: 'OnlineEtc',
    component: () => import(/* webpackChunkName: "OnlineEtc" */ '../views/online/Etc.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['nonmember', 'checkout', 'ordersheet'], 3)
      })
    }
  },

  {
    path: '/guide/online/extra/:page',
    name: 'OnlineExtra',
    component: () => import(/* webpackChunkName: "onlineExtra" */ '../views/online/Extra.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['start', 'detail', 'receipt'], 3)
      })
    }
  },

  {
    path: '/guide/online/check',
    name: 'OnlineCheck',
    component: () => import(/* webpackChunkName: "onlineCheck" */ '../views/online/Check.vue')
  },
  {
    path: '/guide/online/code/:page',
    name: 'OnlineCode',
    component: () => import(/* webpackChunkName: "OnlineCode" */ '../views/online/Code.vue')
  },
  {
    path: '/guide/online/sample',
    name: 'OnlineSample',
    component: () => import(/* webpackChunkName: "onlineSample" */ '../views/online/Sample.vue')
  },

  // 오프라인
  {
    path: '/guide/offline/start',
    name: 'OfflineStart',
    component: () => import(/* webpackChunkName: "OfflineStart" */ '../views/offline/Start.vue')
  },
  {
    path: '/guide/offline/vcat/:page',
    name: 'OfflineVcat',
    component: () => import(/* webpackChunkName: "OfflineVcat" */ '../views/offline/Vcat.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['flow', 'vcat', 'dll'], 3)
      })
    }
  },
  {
    path: '/guide/offline/api/:page',
    name: 'OfflineApi',
    component: () => import(/* webpackChunkName: "OfflineApi" */ '../views/offline/Api.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['flow', 'common', 'pos', 'approval', 'cancel', 'netCancel'], 3)
      })
    }
  },
  {
    path: '/guide/offline/code/:page',
    name: 'OfflineCode',
    component: () => import(/* webpackChunkName: "OfflineCode" */ '../views/offline/Code.vue')
  },
  {
    path: '/guide/offline/check',
    name: 'OfflineCheck',
    component: () => import(/* webpackChunkName: "OfflineCheck" */ '../views/offline/Check.vue')
  },
  {
    path: '/guide/offline/sample',
    name: 'OfflineSample',
    component: () => import(/* webpackChunkName: "OfflineSample" */ '../views/offline/Sample.vue')
  },

  // 오더
  {
    path: '/guide/order/:page',
    name: 'OrderStart',
    component: () => import(/* webpackChunkName: "orderStart" */ '../views/order/Start.vue'),
    beforeEnter: (to, from, next) => {
      next()

      window.addEventListener('scroll', function () {
        scrollOptimization(['ready'], 3)
      })
    }
  },
  {
    path: '/guide/order/vorder/:page',
    name: 'Vorder',
    component: () => import(/* webpackChunkName: "VorderStart" */ '../views/order/Vorder.vue')
  },
  {
    path: '/guide/order/code/:page',
    name: 'OrderCode',
    component: () => import(/* webpackChunkName: "OrderCodeStart" */ '../views/order/Code.vue')
  },
  {
    path: '/guide/order/check',
    name: 'OrderCheck',
    component: () => import(/* webpackChunkName: "OrderCheckStart" */ '../views/order/Check.vue')
  },
  {
    path: '/guide/order/sample',
    name: 'OrderSample',
    component: () => import(/* webpackChunkName: "orderSample" */ '../views/order/Sample.vue')
  },

  // 멤버십
  {
    path: '/guide/membership/:page',
    name: 'MembershipStart',
    component: () => import(/* webpackChunkName: "MembershipStart" */ '../views/membership/Start.vue')
  },
  {
    path: '/guide/membership/vmem/:page',
    name: 'MembershipVmem',
    component: () => import(/* webpackChunkName: "MembershipVmem" */ '../views/membership/Vmem.vue')
  },
  {
    path: '/guide/membership/api/web',
    name: 'MembershipApiWeb',
    component: () => import(/* webpackChunkName: "membershipApiWeb" */ '../views/membership/Web.vue')
  },
  {
    path: '/guide/membership/api/pos',
    name: 'MembershipApiPos',
    component: () => import(/* webpackChunkName: "membershipApiPos" */ '../views/membership/Pos.vue')
  },
  {
    path: '/guide/membership/code/:page',
    name: 'MembershipCode',
    component: () => import(/* webpackChunkName: "MembershipCode" */ '../views/membership/Code.vue')
  },
  {
    path: '/guide/membership/check',
    name: 'MembershipCheck',
    component: () => import(/* webpackChunkName: "MembershipCheck" */ '../views/membership/Check.vue')
  },
  {
    path: '/guide/membership/sample',
    name: 'MembershipSample',
    component: () => import(/* webpackChunkName: "MembershipSample" */ '../views/membership/Sample.vue')
  },

  // 결제/정산 대사
  {
    path: '/guide/calculate',
    name: 'Calculate',
    component: () => import(/* webpackChunkName: "calculate" */ '../views/calculate/Calculate.vue')
  },

  // 연동지원
  {
    path: '/support/app',
    name: 'AppDownload',
    component: () => import(/* webpackChunkName: "AppDownload" */ '../views/support/AppDownload.vue')
  },
  {
    path: '/support/test',
    name: 'TestSetting',
    component: () => import(/* webpackChunkName: "TestSetting" */ '../views/support/TestSetting.vue')
  },

  // 디자인 가이드
  {
    path: '/design/online',
    name: 'DesignOnline',
    component: () => import(/* webpackChunkName: "designOnline" */ '../views/design/Online.vue')
  },
  {
    path: '/design/offline',
    name: 'DesignOffline',
    component: () => import(/* webpackChunkName: "designOffline" */ '../views/design/Offline.vue')
  },
  {
    path: '/design/membership',
    name: 'DesignMembership',
    component: () => import(/* webpackChunkName: "designMembership" */ '../views/design/Membership.vue')
  },

  // FAQ
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/faq/Faq.vue')
  }
]

// 1. 히스토리 모드( 권장, default ) : createWebHistory()
// 2. 해시모드 : createWebHashHistory() - 비권장( seo가 안좋음! )
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// --------------------
// 전역 네비게이션 가드
// --------------------
let prevPage
router.afterEach((to, from) => {
  const headerContainer = document.querySelector('#header')
  const contentContainer = document.querySelector('#container')

  // 메인일 경우
  if (to.name === 'Home') {
    headerContainer.classList.add('main')
    contentContainer.classList.add('main')
  } else {
    headerContainer.classList.remove('main')
    contentContainer.classList.remove('main')
  }

  if (to.name !== prevPage) {
    window.scrollTo(0, 0)
  }

  // 스크롤 페이지 체크
  if (to.params.page) {
    pageCheck(to.params.page)
  }

  prevPage = to.name
})

export default router
