import { useRoute } from 'vue-router'
import axios from './axios.js'

const testMode = 'real'
let menuLinkUri = '/data/menu.json'
let sendMailFormUri = '/data/aclMailForm.json'
let downloadInfoUri = '/data/sampleDownloadList.json'
let apiActionType = 'get'

if (testMode === 'real') {
  menuLinkUri = '/menu/list'
  sendMailFormUri = '/mail/sendmail'
  downloadInfoUri = '/download/sampleDownloadList'
  apiActionType = 'post'
}

// 모바일 체크 : 모바일이면 true 반환
const isMobile = () => {
  let check = false

  // var mobileKeyWords = new Array('iPhone', 'iPad', 'BlackBerry', 'Android', 'Windows CE', 'LG', 'MOT', 'SAMSUNG', 'SonyEricsson');
  if (window.navigator.userAgent.match('/iPhone|iPad|Mobile|UP.Browser|Android|BlackBerry|Windows CE|Nokia|webOS|Opera Mini|SonyEricsson|opera mobi|Windows Phone|IEMobile|POLARIS/')) {
    check = true
  }

  return check
}
// if( isMobile() ){  // 모바일코드 }

// 변수 초기화
const resetActiveMenu = (state) => {
  state.depth1 = 0
  state.depth2 = 0
  state.depth3 = 0
  state.depth4 = 0
  state.depth1_id = ''
  state.depth2_id = ''
  state.depth3_id = ''
  state.depth4_id = ''
  state.depth1_name = ''
  state.depth2_name = ''
  state.depth3_name = ''
  state.depth4_name = ''
  state.depth1_link = ''
  state.depth2_link = ''
  state.depth3_link = ''
  state.depth4_link = ''

  state.depth1_target = ''
  state.depth2_target = ''
  state.depth3_target = ''
  state.depth4_target = ''
}

const globalMenuCheck = (state, pageId) => {
  resetActiveMenu(state)
  if (pageId === undefined) return

  state.gnbList.forEach((item, index, array) => {
    if (pageId === item.id) {
      state.depth1 = index + 1
      state.depth1_id = item.id
      state.depth1_name = item.name
      state.depth1_link = item.link
      if (item.target) state.depth1_target = item.target
      // console.log('대메뉴 : ', state.depth1)
    }

    if (item.nav) {
      const nav = item.nav
      nav.forEach((item2, index2, array2) => {
        if (pageId === item2.id) {
          state.depth1 = index + 1
          state.depth1_id = item.id
          state.depth1_name = item.name
          state.depth1_link = item.link
          if (item.target) state.depth1_target = item.target

          state.depth2 = index2 + 1
          state.depth2_id = item2.id
          state.depth2_name = item2.name
          state.depth2_link = item2.link
          if (item2.target) state.depth2_target = item2.target

          // console.log('메뉴 : ' + state.depth1 + ', 서브 : ' + state.depth2)
        }

        const sub = item2.sub
        if (sub) {
          sub.forEach((item3, index3, array3) => {
            if (pageId === item3.id) {
              state.depth1 = index + 1
              state.depth1_id = item.id
              state.depth1_name = item.name
              state.depth1_link = item.link
              if (item.target) state.depth1_target = item.target
              state.depth2 = index2 + 1
              state.depth2_id = item2.id
              state.depth2_name = item2.name
              state.depth2_link = item2.link
              if (item2.target) state.depth2_target = item2.target
              state.depth3 = index3 + 1
              state.depth3_id = item3.id
              state.depth3_name = item3.name
              state.depth3_link = item3.link
              if (item3.target) state.depth3_target = item3.target
              // console.log('메뉴 : ' + state.depth1 + ', 서브 : ' + state.depth2 + ', ' +
              // '서브2 : ' + state.depth3)
            }

            const sub2 = item3.sub2
            if (sub2) {
              sub2.forEach((item4, index4, array4) => {
                if (pageId === item4.id) {
                  state.depth1 = index + 1
                  state.depth1_id = item.id
                  state.depth1_name = item.name
                  state.depth1_link = item.link
                  if (item.target) state.depth1_target = item.target
                  state.depth2 = index2 + 1
                  state.depth2_id = item2.id
                  state.depth2_name = item2.name
                  state.depth2_link = item2.link
                  if (item2.target) state.depth2_target = item2.target
                  state.depth3 = index3 + 1
                  state.depth3_id = item3.id
                  state.depth3_name = item3.name
                  state.depth3_link = item3.link
                  if (item3.target) state.depth3_target = item3.target
                  state.depth4 = index4 + 1
                  state.depth4_id = item4.id
                  state.depth4_name = item4.name
                  state.depth4_link = item4.link
                  if (item4.target) state.depth4_target = item4.target
                  // console.log('메뉴 : ' + state.depth1 + ', 서브 : ' + state.depth2 + ', ' +
                  //  '서브2 : ' + state.depth3 + ', ' + '서브3 : ' + state.depth4)
                }
              })
            }
          })
        }
      })
    }
  })
  // console.log(state.depth1)
  state.lnbList = state.gnbList[state.depth1 - 1].nav
}

// 다운로드 링크
const getDownLink = async () => {
  const downList = {
    online: '',
    offline: '',
    order: '',
    membership: '',
    app: ''
  }

  const downLinkRes = await axios.$api(downloadInfoUri, apiActionType)
  downList.online = downLinkRes.data.online
  downList.offline = downLinkRes.data.offline
  downList.order = downLinkRes.data.order
  downList.membership = downLinkRes.data.membership
  downList.app = downLinkRes.data.app
  // console.log('온라인', downList.online.value)
  // console.log('오프라인', downList.offline.value.sample.vcatDll.pb)
  // console.log('오더', downList.order.value)
  // console.log('멤버십', downList.membership.value)
  // console.log('개발앱', downList.app.value.aos)

  return downList
}

// 클립보드
const copyToClipboard = (element) => {
  const toast = document.querySelector('#' + element).parentNode.parentNode.querySelector('.toast')
  const copyText = document.querySelector('#' + element).textContent.trim()
  const textArea = document.querySelector('#clipboard_txt')
  textArea.textContent = copyText
  textArea.select()
  document.execCommand('copy')
  textArea.textContent = ''
  // alert('복사완료')

  toast.classList.add('is_show')
  setTimeout(function () {
    toast.classList.remove('is_show')
  }, 2000)
}

// 팝업
const popupOpen = () => {
  document.body.style.overflow = 'hidden'
  document.querySelector('#dimmed').style.display = 'block'
  document.querySelector('#layer_pop').style.display = 'block'
}
const popupClose = () => {
  document.body.style.overflow = 'auto'
  document.querySelector('#dimmed').style.display = 'none'
  document.querySelector('#layer_pop').style.display = 'none'
}
const popupOpenListener = (type) => {
  try {
    if (!type) return
    if (type === 'acl' || type === 'help' || type === 'check' || type === 'offlineAcl' || type === 'vorderMenuReq' || type === 'offlineCheck' ||
      type === 'vorderCheck' || type === 'membershipAcl' || type === 'membershipCheck') {
      popupOpen()
    }
  } catch (e) {
    return false
  }
}
const popupServiceTypeCheck = (route, popupServiceType) => {
  popupServiceType.value = ''
  switch (route.name) {
    case 'NotFound':
      popupServiceType.value = 'help'
      break
    case 'OnlineStart':
      popupServiceType.value = 'acl'
      break
    case 'OnlineCheck':
      popupServiceType.value = 'check'
      break
    case 'OfflineStart':
      popupServiceType.value = 'offlineAcl'
      break
    case 'OfflineCheck':
      popupServiceType.value = 'offlineCheck'
      break
    case 'OrderStart':
      popupServiceType.value = 'vorderMenuReq'
      break
    case 'OrderCheck':
      popupServiceType.value = 'vorderCheck'
      break
    case 'MembershipStart':
      popupServiceType.value = 'membershipAcl'
      break
    case 'MembershipCheck':
      popupServiceType.value = 'membershipCheck'
      break
  }
}

// 스크롤 애니메이션 하기
const initScrollAni = () => {
  const route = useRoute()
  const page = route.params.page
  setTimeout(() => {
    const doc = document
    const targetY = doc.querySelector('#' + page).offsetTop
    if (targetY && targetY > 0) {
      window.scrollTo({
        top: targetY,
        behavior: 'smooth'
      })
    }
  }, 500)
}

export {
  menuLinkUri,
  sendMailFormUri,
  downloadInfoUri,
  apiActionType,
  isMobile,
  resetActiveMenu,
  globalMenuCheck,
  getDownLink,
  copyToClipboard,
  popupOpen,
  popupClose,
  popupOpenListener,
  popupServiceTypeCheck,
  initScrollAni
}
