<template>
  <div class="container_main">
    <div class="content_main" :class="{ 'on': isItemActive == true }">
      <h2 class="main_title">
        <i class="sp_main icon_payco"></i> <i class="sp_main icon_star"></i>
        <span class="blind">PAYCO</span>가 제공하는
        <br><i class="sp_main icon_blancket_l"></i> 안전하고 다양한 <i class="sp_main icon_blancket_r"></i> 솔루션들을
        <br>확인해보세요 <i class="sp_main icon_bang"></i>
      </h2>
      <div class="flex_container">
        <ul class="main_box_list" @mouseenter="boxListOver" @mouseleave="boxListOut">
          <li class="main_box" @mouseover="itemOver(0)">
            <router-link to="/guide/online/start?id=220401001" class="box_link online" aria-disabled="false">
              <div class="txt_group">
                <div class="t_box">
                  <strong class="box_title"><span class="enter">온라인 결제</span></strong>
                  <span class="box_text">
                    <span class="enter">PC, 모바일 환경에 온라인</span> <span class="enter">결제를 손쉽게 연동해보세</span>요. 자동결제, 비회원 결제 등 다양한 결제를 만나보세요.
                  </span>
                </div>
              </div>
            </router-link>
            <span class="box_shadow"></span>
          </li>
          <li class="main_box" @mouseover="itemOver(1)">
            <router-link to="/guide/offline/start?id=220401022" class="box_link offline" aria-disabled="false">
              <div class="txt_group">
                <div class="t_box">
                  <strong class="box_title"><span class="enter">오프라인 결제</span></strong>
                  <span class="box_text">
                    <span class="enter">POS / KIOSK 단말기에</span> <span class="enter">오프라인 결제를 손쉽게</span> 연동해보세요. NFC, 바코드, QR 결제 모두 제공합니다.
                  </span>
                </div>
              </div>
            </router-link>
            <span class="box_shadow"></span>
          </li>
          <li class="main_box" @mouseover="itemOver(2)">
            <router-link to="/guide/order/start?id=220401028" class="box_link smart_order" aria-disabled="false">
              <div class="txt_group">
                <div class="t_box">
                  <strong class="box_title"><span class="enter">오더 클라우드</span></strong>
                  <span class="box_text">
                    <span class="enter">POS 단말기에 오더 서비스</span> <span class="enter">를 연동하여 배달, 포장</span> 주문을 손쉽게 관리해보세요.
                  </span>
                </div>
              </div>
            </router-link>
            <span class="box_shadow"></span>
          </li>
          <li class="main_box" @mouseover="itemOver(3)">
            <router-link to="/guide/membership/start?id=220401033" class="box_link cloud" aria-disabled="false">
              <div class="txt_group">
                <div class="t_box">
                  <strong class="box_title"><span class="enter">멤버십 클라우드</span></strong>
                  <span class="box_text">
                    <span class="enter">간편하게 멤버십 서비스를</span> <span class="enter">연동하여 회원 관리,</span> <span class="enter">데이터 분석, 마케팅까지</span> 이용해보세요.
                  </span>
                </div>
              </div>
            </router-link>
            <span class="box_shadow"></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
export default {
  name: 'MainVisual',
  setup (props, context) {
    const isItemActive = ref({})

    // --------------------
    // 메인 배너 자동 롤링
    // --------------------
    const max = 4
    const sec = 5
    let counter = 0
    let timer = null

    onMounted(() => {
      setTimeout(init, 300)
    })

    // 페이지를 벗어나면 반드시 리셋 해야 한다.
    onUnmounted(() => {
      counter = 0
      stopBanner()
    })

    // 시작
    const init = () => {
      next()
    }

    // 자동롤링
    const next = () => {
      stopBanner()
      const item = document.querySelector('.main_box_list li:nth-child(' + (counter + 1) + ')')
      item.classList.add('on')

      counter++
      if (counter > max - 1) {
        counter = 0
      }

      timer = setTimeout(next, 1000 * sec)
    }

    // 정지
    const stopBanner = () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      const items = document.querySelectorAll('.main_box')
      items.forEach((item, index) => {
        item.classList.remove('on')
      })
    }

    // 마우스 오버
    const itemOver = (index) => {
      counter = index
      stopBanner()
    }

    // 마우스 배너 진입시
    const boxListOver = () => {
      isItemActive.value = true
      stopBanner()
    }

    // 마우스 배너 벗어났을때
    const boxListOut = () => {
      isItemActive.value = false
      next()
    }

    return {
      isItemActive,
      itemOver,
      boxListOver,
      boxListOut
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/base/variables.scss";
.container_main{
  max-width: $pc_max_size;
  min-width: $pc_size;
  height: 890px;
  margin: 0 auto;
  background:url('~@/assets/img/@img_bg.png') no-repeat center -28px;
  .content_main{
    min-width: $pc_min_size;
    height:100%;
    margin:0 auto;
    &.on{
      background:url('~@/assets/img/@img_bg2.png') no-repeat center -28px;
    }
    .main_title{
      padding:113px 0 45px;
      padding-top: 100px;
      padding-left: 3px;
      font-size:48px;
      line-height:73px;
      letter-spacing:0.02em;
      width: $pc_min_size;
      min-width: $pc_min_size;
      margin: 0 auto;
      .sp_main{
        display:inline-block;
        background:url('~@/assets/img/sp_main.png') no-repeat;
        vertical-align:middle;
        &.icon_payco{
          position:relative;
          top:-5px;
          width:167px;
          height:38px;
          background-position:-311px -10px;
        }
        &.icon_star{
          position:relative;
          top:-5px;
          width:54px;
          height:54px;
          background-position:-200px -3px;
          margin-right:12px;
        }
        &.icon_blancket_l{
          position:relative;
          top:-4px;
          width:33px;
          height:54px;
          background-position:0 0;
        }
        &.icon_blancket_r{
          position:relative;
          top:-4px;
          width:40px;
          height:54px;
          background-position:-152px -4px;
        }
        &.icon_bang{
          position:relative;
          top:-4px;
          width:40px;
          height:54px;
          background-position:-270px -5px;
        }
      }
    }
    .flex_container {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      .main_box_list {
        font-size:0;
        display: flex;
        justify-content: center;
        align-items: top;
        box-sizing: border-box;
        width: 1324px;
        .main_box {
          position:relative;
          display:inline-block;
          /* width:265px; */
          height:460px;
          vertical-align:top;
          box-sizing:border-box;
          border-radius:4px;
          vertical-align:top;
          box-sizing:border-box;
          flex-basis: 20.5%;
          flex-shrink: 0;
          margin: 0 0px;
          transition: all .25s;
          box-sizing: border-box;
          a{
            position: relative;
            display:inline-block;
            .txt_group {
              width: auto;
              height: 350px;
              position: relative;
              display: block;
              margin-right: 30px;
              transition: height .18s ease-out;
              overflow: hidden;
              .t_box {
                position: relative;
                transition: all .22s ease-out .03s;
                .enter {
                  display: block;
                }
              }
            }
          }
          &:hover, &:focus, &.on {
            flex-basis: 22%;
            background: $main_color;
            a[aria-disabled="false"] {
              &.box_link {
                background:$main_color;
                color:#fff;
                &.online:before {
                  background-position:0 -58px;
                }
                &.offline:before {
                  background-position:-140px -58px;
                }
                &.smart_order:before {
                  background-position:-280px -58px;
                }
                &.cloud:before {
                  background-position:-420px -58px;
                }
                &:after {
                  background-position:-23px 0;
                }
              }
              .txt_group {
                height: 367px;
                .t_box {
                  /* transform: translateY(-20px); */
                  .enter {
                    display: inline;
                  }
                  .box_title {
                    font-size: 22px;
                    /*margin-bottom: 5px;*/
                    letter-spacing: -1px;
                  }
                  .box_text{
                    font-size: 18px;
                  }
                }
              }
              &:before {
                /*transform: translateY(-1px);*/
              }
              &:after {
                /* transform: translateY(-30px); */
              }
            }
          }
          /*
          &:last-child, &.on {
            cursor: default;
            &:hover, &:focus {
              flex-basis: 20.5%;
              background: #fff;
            }
          }
          */

          &:after {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:8px;
            border-radius:4px 4px 0 0;
            background: $main_color;
          }
          .box_link {
            text-decoration: none;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 40px 0 57px 32px;
            color: $text_color;
            border-radius:4px;
            background:#fff;
            transition: background .15s;
            box-sizing: border-box;
            box-shadow: 0 14px 20px 0 rgba(0,0,0,.1);
            position: relative;
            cursor: pointer;
            &:before {
              content:'';
              position: absolute;
              top:30px;
              left:0px;
              width:140px;
              height:120px;
              background:url('~@/assets/img/sp_main.png') no-repeat 0 -178px;
              transition: all .25s ease-out;
            }
            &.offline {
              &:before {
                background-position:-140px -178px;
              }
            }
            &.smart_order {
              &:before {
                background-position:-280px -178px;
              }
            }
            &.cloud {
              &:before {
                background-position:-420px -178px;
              }
            }
            &:after{
              content:'';
              position:absolute;
              bottom:35px;
              left:32px;
              width:33px;
              height:33px;
              background:url('~@/assets/img/sp_main.png') no-repeat -57px 0;
              transition: all .2s ease-out .05s;
            }
            .box_title {
              display:block;
              margin-top:143px;
              font-size:22px;
              font-weight:700;
              line-height:33px;
              letter-spacing:0.008em;
              position:relative;
              left:0px;
            }
            .box_text {
              display:inline-block;
              margin-top:16px;
              font-size:14px;
              line-height:26px;
              letter-spacing:0.008em;
              position:relative;
              left:1px;
            }
          }
          .box_link[aria-disabled="true"] {
            border-left:1px solid #dadada;
            border-right:1px solid #dadada;
            border-bottom:1px solid #dadada;
            box-shadow:none;
            pointer-events:none;
            cursor: normal;
            .box_text {
              &.hidden {
                display:none;
              }
            }
          }
          .box_link[aria-disabled="true"] + .box_shadow {
            display:block;
          }
          .box_shadow {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(255,255,255,0.5);
            z-index:1;
            display:none;
            border-radius: 4px;
          }
        }
        .main_box + .main_box {
          margin-left:13px
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .container_main{
    max-width: auto;
    min-width: auto;
    height: 820px;
    background-position: center -60px;
    .content_main {
      min-width: auto;
      &.on{
        background-position: center -60px;
      }
      .main_title {
        width: 100%;
        min-width: auto;
        box-sizing: border-box;
        font-size: 42px;
        margin-left: 0px;
        padding-bottom: 40px;
        padding-top: 70px;
      }
      .flex_container {
        .main_box_list {
          width: 100%;
          .main_box {
            height:420px;
            flex-basis: 23%;
            a {
              .txt_group {
                height: 380px;
              }
            }
            &:hover, &:focus, &.on {
              flex-basis: 25%;

              a[aria-disabled=false] {
                .txt_group {
                  .t_box {
                    .box_title {
                      font-size: 22px;
                    }
                  }
                }
              }
              .box_link {
                /* padding-top: 20px; */
                .txt_group {
                  .t_box {
                    .enter {
                      &.two {
                        display: none;
                      }
                    }
                  }
                }
                &:after {
                  /* bottom: 0px; */
                }
              }
            }
            .box_link {
              padding-top: 5px;
              &:before {
                top: 20px;
              }
              &:after {
                /* bottom: 20px; */
              }
              .txt_group {
                .t_box {
                  .box_title {
                    font-size: 21px;
                  }
                }
              }

              .txt_group {
                .t_box {
                  .enter {
                    display: inline;
                    &.two {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
