<template>
  <div class="popup_wrap">
    <div v-if="useMail===false" class="email_off">
      <div v-if="isQuestion===true">
        <h2><b>PAYCO</b> 기술지원팀 메일로 문의해 주세요.</h2><br/>
        <p style="margin-left: -25px; margin-bottom: 3px;" >온라인 결제 연동 : dl_payco_ts@nhn.com</p>
        <p style="margin-left: 10px; margin-bottom: 3px;">오프라인 결제 연동 : dl_payco_tsoff@nhn.com</p>
        <p style="margin-left: 8px; margin-bottom: 3px;">멤버십 클라우드 : dl_payco_tsmem@nhn.com</p>
        <p style="margin-left: 40px;">오더 클라우드 : dl_payco_tsorder@nhnpayco.com</p>
        <button type="button" class="btn_close_popup" @click.prevent="popupClose">
          <span class="icon_close">
            <i class="blind">팝업닫기</i>
          </span>
        </button>
      </div>
      <div v-if="isQuestion===false">
        <h2>PAYCO 기술지원팀( <b>{{ emailText }}</b> )</h2>
        <p>메일로 문의 주시기 바랍니다.</p>
        <!--<h2>PAYCO 기술지원팀 메일로 문의해 주세요</h2>
        <h2>온라인 기술지원( <b>{{ emailText }}</b> )</h2>-->
        <button type="button" class="btn_close_popup" @click.prevent="popupClose">
          <span class="icon_close">
            <i class="blind">팝업닫기</i>
          </span>
        </button>
      </div>
    </div>
    <div v-else>
      <form name="email_form" @submit.prevent="sendForm">
      <fieldset>
        <div class="popup_header">
          <h1 class="popup_title">문의 메일 보내기</h1>
        </div>
        <div class="popup_content">
          <div class="tbl_wrap">
            <table class="tbl tbl_horizon">
              <caption><span>문의 내용 상세</span></caption>
              <colgroup>
                <col style="width:130px">
                <col>
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">서비스 구분</th>
                  <td>
                    <div class="select_designbox">
                      <input type="hidden" readonly v-model="InquiryType" required>
                      <span class="selText" @click="selTextClick" :class="{ 'on': isSelClick === true }">{{ inquiryText }}</span>
                      <ul class="select_list" v-if="isSelClick">
                        <li data-type="online" @click="inquiryClick">PAYCO 온라인 결제</li>
                        <li data-type="offline" @click="inquiryClick">PAYCO 오프라인 결제</li>
                        <li data-type="order" @click="inquiryClick">PAYCO 오더</li>
                        <li data-type="membership" @click="inquiryClick">PAYCO 멤버십</li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">제목</th>
                  <td><p class="txt_oneline"><input type="text" name="title" v-model="title" required/></p></td>
                </tr>
                <tr>
                  <th scope="row">내용</th>
                  <td><p class="txt_scroll"><textarea name="mainText" v-model="mainText" cols="10" rows="5" required></textarea></p></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="txt_note">연락처를 남겨주시면 보다 상세하게 답변 드릴께요.</p>
          <div class="tbl_wrap">
            <table class="tbl tbl_horizon">
              <caption><span>연락처</span></caption>
              <colgroup>
                <col style="width:130px">
                <col>
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">휴대폰 번호</th>
                  <td><input type="tel" name="cellPhone" v-model="cellPhone" required></td>
                </tr>
                <tr>
                  <th scope="row">이메일 주소</th>
                  <td><p class="txt_oneline"><input type="email" name="senderMail" v-model="senderMail" required></p></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="privacy">
            <h4>
              <input type="checkbox" id="privacyUseYn" name="privacyUseYn" value="y" v-model="privacyUseYn" @click="privacyFnc"/>
              <label for="privacyUseYn">개인정보 수집 이용 동의</label>
            </h4>
            <div class="box" v-if="privacyUseYn">
              <ol>
                <li>수집 목적 : PAYCO 결제 개발 관련 문의 처리</li>
                <li>수집 항목 : 휴대폰번호, 이메일 주소, 문의 내용</li>
                <li><b class="txt_blue">보유 및 이용기간</b><b> : 개인정보 수집 목적 달성 시 까지</b></li>
              </ol>
              <br>
              <p>
                개인정보 수집 이용 동의를 거부하실 수 있으며,<br>
                동의 거부시 PAYCO 결제 개발 관련 문의가 제한될 수 있습니다.
              </p>
            </div>
          </div>
        </div>

        <div class="popup_footer">
          <button type="button" class="btn btn_gray" @click.prevent="popupClose">닫기</button>
          <button type="submit" class="btn">메일 보내기</button>
        </div>

        <button type="button" class="btn_close_popup" @click.prevent="popupClose">
          <span class="icon_close">
            <i class="blind">팝업닫기</i>
          </span>
        </button>
      </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import axios from '../common/axios'

export default {
  name: 'LayerMailPopup',
  props: {
    popupServiceType: {
      type: String
    },
    sendMailFormUri: {
      type: String
    },
    apiActionType: {
      type: String
    }
  },
  emits: ['popup-close'],
  setup (props, context) {
    // --------
    // 초기화
    // --------
    const useMail = ref(false)
    const title = ref('')
    const mainText = ref('')
    const cellPhone = ref('')
    const senderMail = ref('')
    const privacyUseYn = ref(false)
    const privacyTimer = ref(null)
    const isQuestion = ref(false)
    const serviceType = computed(() => {
      if (props.popupServiceType === 'acl' || props.popupServiceType === 'check') {
        return 'online'
      } else if (props.popupServiceType === 'offlineAcl' || props.popupServiceType === 'offlineCheck') {
        return 'offline'
      } else if (props.popupServiceType === 'vorderCheck' || props.popupServiceType === 'vorderMenuReq') {
        return 'order'
      } else if (props.popupServiceType === 'membershipAcl' || props.popupServiceType === 'membershipCheck') {
        return 'membership'
      } else if (props.popupServiceType === 'help') {
        return 'question'
      } else {
        return props.popupServiceType
      }
    })

    // ------------------------------
    // 서비스 구분 : select box 관련
    // ------------------------------
    const selData = ref('')
    const InquiryType = computed(() => {
      if (selData.value === '') {
        return serviceType.value
      } else {
        return selData.value
      }
    })

    const isSelClick = ref(false)
    const selTextClick = () => {
      isSelClick.value = isSelClickCheck(isSelClick.value)
    }

    const isSelClickCheck = (isVisible) => {
      if (isVisible === false) {
        return true
      } else {
        return false
      }
    }

    const inquiryText = computed(() => {
      return returnInquiryText(InquiryType.value)
    })

    const inquiryClick = (e) => {
      selData.value = e.target.dataset.type
      isSelClick.value = isSelClickCheck(isSelClick.value)
    }

    const returnInquiryText = (type) => {
      if (type === 'online') {
        return 'PAYCO 온라인 결제'
      } else if (type === 'offline') {
        return 'PAYCO 오프라인 결제'
      } else if (type === 'order') {
        return 'PAYCO 오더'
      } else if (type === 'membership') {
        return 'PAYCO 멤버십'
      } else if (type === 'question') {
        return 'PAYCO 문의하기'
      } else {
        return false
      }
    }

    // 임시: 이메일 대체
    const emailText = computed(() => {
      return returnEmailText(InquiryType.value)
    })

    const returnEmailText = (type) => {
      if (type === 'online') {
        return 'dl_payco_ts@nhn.com'
      } else if (type === 'offline') {
        return 'dl_payco_tsoff@nhn.com'
      } else if (type === 'order') {
        return 'dl_payco_tsorder@nhnpayco.com'
      } else if (type === 'membership') {
        return 'dl_payco_tsmem@nhn.com'
      } else if (type === 'question') {
        isQuestion.value = true
        return 'dl_payco_ts@nhn.com'
      } else {
        return false
      }
    }

    const privacyFnc = (e) => {
      const popupWrap = document.querySelector('#layer_pop .popup_wrap')
      clearPrivacyTimer()
      if (privacyUseYn.value === false) {
        privacyTimer.value = setTimeout(function () {
          popupWrap.scrollTo({
            top: popupWrap.scrollHeight,
            behavior: 'smooth'
          })
        }, 200)
      } else {
        popupWrap.scrollTo(0, 0)
      }
    }

    const clearPrivacyTimer = () => {
      if (privacyTimer.value == null) return
      clearTimeout(privacyTimer)
      privacyTimer.value = null
    }

    // --------------------
    // 초기화 및 팝업닫기
    // --------------------
    const resetData = () => {
      title.value = ''
      mainText.value = ''
      cellPhone.value = ''
      senderMail.value = ''
      selData.value = ''
      isSelClick.value = false

      privacyUseYn.value = false
      privacyTimer.value = null
      isQuestion.value = false
    }

    const popupClose = (e) => {
      clearPrivacyTimer()
      resetData()
      context.emit('popup-close') // 이벤트 발생
    }

    // ---------
    // 폼 전송
    // ---------
    const sendForm = async (e) => {
      const serviceTypeData = InquiryType.value // 서비스 타입
      const titleData = title.value // 제목
      const mainTextData = mainText.value // 내용
      const cellPhoneData = cellPhone.value // 휴대폰
      const senderMailData = senderMail.value // 이메일

      if (privacyUseYn.value === false) {
        alert('개인정보 수집 이용 동의가 필요합니다.')
        return
      }

      // 문의내용 구분
      let titleDivision = ''
      if (props.popupServiceType === 'acl' || props.popupServiceType === 'offlineAcl' ||
        props.popupServiceType === 'membershipAcl') {
        titleDivision = '[ACL 등록요청] '
      } else if (props.popupServiceType === 'check' || props.popupServiceType === 'offlineCheck' ||
        props.popupServiceType === 'vorderCheck' || props.popupServiceType === 'membershipCheck') {
        titleDivision = '[검수요청] '
      } else if (props.popupServiceType === 'vorderMenuReq') {
        titleDivision = '[메뉴 등록요청] '
      } else if (props.popupServiceType === 'help') {
        titleDivision = '[문의] '
      }

      const data = {
        serviceType: serviceTypeData,
        title: titleDivision + titleData,
        mainText: mainTextData,
        cellPhone: cellPhoneData,
        senderMail: senderMailData
      }

      const res = await axios.$api(props.sendMailFormUri, props.apiActionType, data)
      if (res.data.code === 0) {
        alert('메일이 성공적으로 전송되었습니다.')
        popupClose()
      } else {
        alert(res.data.code + ' : ' + res.data.message)
      }
    }

    return {
      useMail,
      emailText,
      isQuestion,
      returnEmailText,

      popupClose,
      sendForm,
      resetData,
      serviceType,
      title,
      mainText,
      cellPhone,
      senderMail,
      privacyUseYn,
      InquiryType,
      inquiryClick,
      selData,
      returnInquiryText,
      inquiryText,
      privacyFnc,
      clearPrivacyTimer,
      selTextClick,
      isSelClick
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/base/variables.scss";
  .popup_wrap {
    overflow-y:auto;
    position:fixed;
    top:50%;
    left:50%;
    z-index:102;
    width:540px;
    max-height:90vh;
    background-color:#fff;
    transform:translate(-50%,-50%);

    &::-webkit-scrollbar {
      width: 3px;
      background: #f4f4f4;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1.5px;
      background-color: #e4e4e4;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      width: 10px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      background-color: #eee;
      background-clip: content-box;
    }
    scrollbar-width: thin;
    scrollbar-color: gray transparent;

    .email_off {
      position: relative;
      padding: 50px 0;
      h2 {
        text-align: center;
        font-size: 19px;
        b {
          color: #fa2828;
        }
        margin-top: 15px;
        margin-bottom: 10px;
      }
      p {
        font-size: 17px;
        color: #666;
        text-align: center;
      }
    }

    .btn_close_popup {
      position:absolute;
      top:0;
      right:0;
      padding:10px;
      z-index: 1;
      cursor: pointer;
      .icon_close {
        display:block;
        width:38px;
        height:38px;
        position: relative;
        &:before{
          content:'';
          display:block;
          position:absolute;
          top:6px;
          left:18px;
          width:1px;
          height:27px;
          background-color:#464646;
          -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
        }
        &:after{
          content:'';
          display:block;
          position:absolute;
          top:6px;
          left:18px;
          width:1px;
          height:27px;
          background-color:#464646;
          -webkit-transform:rotate(135deg);
          transform:rotate(135deg);
        }
      }
    }

    .popup_header {
      padding:40px 40px 0;
      .popup_title{
        font-size:20px;line-height:29px;
      }
    }

    .popup_content {
      padding:18px 40px 40px;
      .txt_blue {
        color: #0b5fc9;
      }
      .privacy {
        h4 {
          font-size:20px;
          margin-top: 30px;
          line-height: 20px;
          position: relative;
          label {
            display: block;
            &:before {
              content: '';
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0px;
              top: 15px;
              background: url('~@/assets/img/check_icon.png') no-repeat 0 0;
            }
          }

          input[type='checkbox']:checked + label{
            &::before {
              background-position: 0 -30px;
            }
          }
        }
        .box {
          background-color: #f2f2f2;
          margin:0px;
          margin-top: 20px;
          padding: 20px;
          box-sizing: border-box;
          font-size: 15px;
          border-radius: 6px;
          ol {
            li {
              list-style-type: decimal;
              list-style-position: inside;
              b {
                font-size: 17px;
              }
            }
          }
        }
      }
      .txt_note{
        padding:20px 0;
        font-size: $font_size;
        font-weight:700;
        line-height:30px;
        letter-spacing:-.04em;
        color:$text_color;
        text-align:center;
      }
      .tbl_wrap {
        overflow:hidden;
        border:1px solid #e4e8eb;
        border-radius:6px;
        margin-bottom: 0px;
        .tbl{
          border-top:0;
          table-layout:fixed;
          .select_designbox {
            position: relative;
            .selText {
              display:block;
              overflow: hidden;
              position: relative;
              width: 100%;
              padding: 0 32px 0 0;
              font-size: 16px;
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              box-sizing: border-box;
              cursor: pointer;
              &:after {
                content: "";
                position: absolute;
                right: 5px;
                top: 9px;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-bottom: 9px solid transparent;
                border-top: 9px solid #747474;
              }

              &.on {
                &:after {
                  content: "";
                  position: absolute;
                  right: 5px;
                  top: 0px;
                  border-right: 6px solid transparent;
                  border-left: 6px solid transparent;
                  border-top: 9px solid transparent;
                  border-bottom: 9px solid #747474;
                }
                ul {
                  display: block;
                }
              }
            }
            .select_list {
              position: absolute;
              top: 46px;
              left: -20px;
              width: calc(100% + 41px);
              padding: 10px;
              border: solid #e4e8eb;
              border-width: 0 1px 1px;
              border-radius: 0 0 4px 4px;
              box-sizing: border-box;
              box-shadow: 0 14px 18px rgb(0 0 0 / 8%);
              background: white;
              li {
                width: calc(100% - 20px);
                padding: 6px 10px;
                border-radius: 4px;
                font-size: 16px;
                cursor: pointer;
                &:hover, &.is_selected {
                  background-color: #f8f8f8;
                  font-weight: 700;
                }
              }
            }
          }

          &.tbl_horizon {
            td {
              padding:10px 20px
            }
          }
          tr {
            &:last-child {
              td, th {
                border-bottom:0;
              }
            }
            th, td {
              color:$text_color;
            }
            td {
              .btn_select {
                overflow:hidden;
                position:relative;
                width:100%;
                padding:0 32px 0 0;
                font-size: $font_size;
                text-align:left;
                text-overflow:ellipsis;
                white-space:nowrap;
                &:after {
                  position:absolute;
                  top:0;
                  right:0;
                }
              }
              .txt_oneline {
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
              }
              .txt_scroll {
                overflow-y:auto;
                max-height:124px;
                line-height:27px;
              }
            }
          }
        }
      }
    }
    .popup_footer {
      width:100%;
      font-size:0;
      button {
        cursor: pointer;
      }
      .btn {
        min-width:0;
        width:50%;
        height:80px;
        margin-left:0;
        font-size:18px;
        font-weight:700;
        letter-spacing:0;
      }
      .btn_gray{
        background:#ddd;
        border-color:#ddd;
        color:#464646
      }
    }
  }
</style>
