<template>
  <MainVisual />
</template>

<script>
// @ is an alias to /src
import MainVisual from '@/components/MainVisual.vue'

export default {
  name: 'Home',
  components: {
    MainVisual
  }
}
</script>
