<template>
  <div class="error_page">
    <article>
      <h2><b>404</b> 요청하신 페이지를 찾을 수 없습니다.</h2>
      <p>
        올바른 주소인지 확인해주시기 바랍니다.<br>
        문의사항은 <a href="" @click.prevent="requestCheck" class="txt_hightlight">dl_payco_ts@nhn.com</a> 으로 문의 부탁드립니다.
      </p>
      <br><br>
      <a href="/" class="btn">메인 화면으로</a>
    </article>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  setup (props, context) {
    const requestCheck = () => {
      context.emit('popup-open', 'help')
    }
    return {
      requestCheck
    }
  }
}
</script>
<style scoped lang="scss">
  .error_page {
    text-align: center;
    font-size: 20px;
    height: 560px;
    display: table;
    width: auto;
    margin: 0 auto;
    padding-right: 175px;
    box-sizing: border-box;
    article {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      h2 {
        font-size: 30px;
        text-align: center;
        vertical-align: middle;
        b {
          color: red;
        }
      }
      .btn {
        min-width: 270px;
        padding: 16px;
        background-color: #fa2828;
        border: 1px solid #fa2828;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        text-decoration: none;
      }
      p {
        margin-top: 20px;
        .txt_hightlight {
          color: #328cff;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }
</style>
