<template>
  <div id="u_skip">
    <a href="#gnb"><span class="blind">상단 메뉴로 바로가기</span></a>
    <a href="#lnb"><span class="blind">메인 메뉴로 바로가기</span></a>
    <a href="#content"><span class="blind">본문으로 바로가기</span></a>
  </div>

  <div id="dimmed"></div>
  <div id="layer_pop" class="main">
    <layer-mail-popup
      :popupServiceType="popupServiceType"
      :sendMailFormUri="sendMailFormUri"
      :apiActionType="apiActionType"
      @popup-close="popupClose"
    />
  </div>

  <div class="blind">
    <textarea id="clipboard_txt"></textarea>
  </div>

  <div id="layout">
    <header id="header">
      <section class="header_inner">
        <h1>
          <router-link to="/">
            <img src="@/assets/img/logo.png" alt="PAYCO 로고이미지" class="img" />
            <img src="@/assets/img/logo_txt.png" alt="DEVELOPERS" class="txt" />
          </router-link>
        </h1>
        <nav id="gnb">
          <li :key="i" v-for="(gnb, i) in state.gnbList" :class="{ on: state.depth1 == i + 1 }">
            <h2 v-if="gnb.target">
              <a :href="gnb.link" :target="gnb.target">{{ gnb.name }}</a>
            </h2>
            <h2 v-else>
              <router-link :to="{ path: gnb.link, query: { id: gnb.id } }">{{ gnb.name }}</router-link>
            </h2>
          </li>
          <li>
            <h2><a href="" @click.prevent="bottomHelpMenu" class="link">문의하기</a></h2>
          </li>
        </nav>
      </section>
    </header>

    <!--
    <div id="breadcrumb">
      <nav class="breadcrumb_list">
        <li v-if="state.depth1_name"><router-link :to="{ path: state.depth1_link, query:{id: state.depth1_id} }">{{ state.depth1_name }}</router-link></li>
        <li v-if="state.depth2_name"><router-link :to="{ path: state.depth2_link, query:{id: state.depth2_id} }">{{ state.depth2_name }}</router-link></li>
        <li v-if="state.depth3_name"><router-link :to="{ path: state.depth3_link, query:{id: state.depth3_id} }">{{ state.depth3_name }}</router-link></li>
        <li v-if="state.depth4_name"><router-link :to="{ path: state.depth4_link, query:{id: state.depth4_id} }">{{ state.depth4_name }}</router-link></li>
      </nav>
    </div>
    -->

    <div id="container">
      <aside id="lnb">
        <nav>
          <li :key="i" v-for="(menu, i) in state.lnbList" :class="{ on: state.depth2 === i + 1 }">
            <h2 v-if="menu.target">
              <a :href="menu.link" :target="menu.target">{{ menu.name }}</a>
            </h2>
            <h2 v-else>
              <router-link :to="{ path: menu.link, query: { id: menu.id } }">{{ menu.name }}</router-link>
            </h2>
            <ul v-if="menu.sub">
              <li v-for="(sub, k) in menu.sub" :key="k" :class="{ on: state.depth2 === i + 1 && state.depth3 === k + 1, not: !sub.sub2 }">
                <h3 :class="{ child: sub.sub2 }" v-if="sub.target">
                  <a :href="sub.link" :target="sub.target">{{ sub.name }}</a>
                </h3>
                <h3 :class="{ child: sub.sub2 }" v-else>
                  <router-link :to="{ path: sub.link, query: { id: sub.id } }">{{ sub.name }}</router-link>
                </h3>

                <ul v-if="sub.sub2">
                  <li
                    v-for="(sub2, q) in sub.sub2"
                    :key="q"
                    :class="{ on: state.depth2 === i + 1 && state.depth3 === k + 1 && state.depth4 === q + 1 }"
                  >
                    <h4 v-if="sub2.target">
                      <a :href="sub2.link" :target="sub2.target">{{ sub2.name }}</a>
                    </h4>
                    <h4 v-else>
                      <router-link :to="{ path: sub2.link, query: { id: sub2.id } }">{{ sub2.name }}</router-link>
                    </h4>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </nav>
      </aside>
      <section id="content" class="content">
        <aside id="pageTop">
          <ul>
            <li class="top" @click="pageTopClick"></li>
            <li class="bottom" @click="pageBottomClick"></li>
          </ul>
        </aside>
        <router-view v-on:popup-open="popupOpenListener" />
      </section>
    </div>

    <footer id="footer">
      <div class="footer__info">
        <ul class="footer__info--list">
          <li class="footer__copyrights">&copy;NHN PAYCO Corp. All Right Reserved.</li>
        </ul>
        <div :class="['footer__family', familySiteClicked ? 'open' : '']">
          <a href="#" class="footer__site" @click.prevent="familySiteClicked = !familySiteClicked">
            <span>패밀리 사이트</span>
            <em class="arrow_down"
              ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.44594 3.55408L1.83984 4.16017L6.00003 8.32036L10.1602 4.16017L9.55413 3.55408L6.00003 7.10817L2.44594 3.55408Z"
                  fill="#111111"
                  fill-opacity="0.6"
                /></svg
            ></em>
          </a>
          <ul class="footer__list">
            <li><a href="https://www.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 닷컴</a></li>
            <li><a href="https://developers.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 로그인 개발자센터</a></li>
            <li><a href="https://partner.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 파트너센터</a></li>
            <li><a href="https://marketing.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 마케팅센터</a></li>
            <li><a href="https://bizplus.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 기업복지</a></li>
            <li><a href="https://gift.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 상품권</a></li>
            <li><a href="https://order.payco.kr/main" target="_blank" @click="initFamilySiteClicked">PAYCO 오더클라우드</a></li>
            <li><a href="https://cert.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 인증센터</a></li>
            <li><a href="https://membership.payco.com/" target="_blank" @click="initFamilySiteClicked">PAYCO 멤버십 클라우드</a></li>
            <li class="top_border"><a href="https://www.toast.com/kr" target="_blank" @click="initFamilySiteClicked">NHN Cloud</a></li>
            <li><a href="https://inside.nhn.com/br/index.nhn" target="_blank" @click="initFamilySiteClicked">INSIDE NHN</a></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import LayerMailPopup from '@/components/LayerMailPopup.vue'
import { onMounted, ref, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from './common/axios'
import {
  menuLinkUri,
  sendMailFormUri,
  apiActionType,
  popupOpen,
  popupClose,
  popupOpenListener,
  resetActiveMenu,
  globalMenuCheck,
  popupServiceTypeCheck,
} from './common/common.js'

export default {
  components: { LayerMailPopup },
  setup(props, context) {
    const route = useRoute()
    const state = reactive({
      gnbList: [],
      lnbList: [],
      depth1: 0,
      depth2: 0,
      depth3: 0,
      depth4: 0,
      depth1_id: '',
      depth2_id: '',
      depth3_id: '',
      depth4_id: '',
      depth1_name: '',
      depth2_name: '',
      depth3_name: '',
      depth4_name: '',
      depth1_link: '',
      depth2_link: '',
      depth3_link: '',
      depth4_link: '',
    })

    const popupServiceType = ref('')
    let isMainPage = true

    // 인스턴스가 마운트된 후
    onMounted(async () => {
      // console.log('mounted')
      const res = await axios.$api(menuLinkUri, apiActionType)
      state.gnbList = res.data

      watch(
        () => {
          // state.pageNo = route.query.id
          // if (route.params.page) console.log(route.params.page)

          // 팝업 타입 체크
          popupServiceTypeCheck(route, popupServiceType)

          // 메뉴 체크
          globalMenuCheck(state, route.query.id)

          if (route.name === 'Home') {
            document.querySelector('#layout').classList.add('main')
          } else {
            document.querySelector('#layout').classList.remove('main')
            isMainPage = false
          }

          return route.query.id
        },
        async (newId) => {}
      )

      const pcSize = 1260
      const pageTopBtnCheck = () => {
        const win = window
        const doc = document
        if (isMainPage === false) {
          const pageTop = doc.querySelector('#pageTop')

          if (win.scrollY > win.innerHeight / 2) {
            if (win.innerHeight + window.pageYOffset >= doc.body.scrollHeight - 300) {
              pageTop.classList.remove('on')
            } else {
              if (window.innerWidth >= pcSize) {
                pageTop.classList.add('on')
              }
            }
          } else {
            pageTop.classList.remove('on')
          }
        }

        if (win.innerHeight + win.pageYOffset >= doc.body.scrollHeight) {
          doc.querySelector('#lnb').classList.add('scroll_btm')
        } else {
          doc.querySelector('#lnb').classList.remove('scroll_btm')
        }
      }

      window.addEventListener('resize', function () {
        const doc = document
        const pageTop = doc.querySelector('#pageTop')
        if (window.innerWidth < pcSize) {
          pageTop.classList.remove('on')
        } else {
          pageTopBtnCheck()
        }
      })

      window.addEventListener('scroll', function () {
        pageTopBtnCheck()
      })
    })

    // 상위로
    const pageTopClick = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    // 아래로
    const pageBottomClick = () => {
      const targetY = document.body.scrollHeight
      window.scrollTo({
        top: targetY,
        behavior: 'smooth',
      })
    }

    // footer 기술지원 메뉴
    const bottomHelpMenu = () => {
      popupServiceType.value = 'help'
      // popupServiceType.value = 'all'
      popupOpen()
    }

    return {
      state,
      sendMailFormUri,
      apiActionType,
      resetActiveMenu,
      globalMenuCheck,
      popupOpen,
      popupClose,
      popupOpenListener,
      popupServiceType,
      isMainPage,
      pageTopClick,
      pageBottomClick,
      bottomHelpMenu,
    }
  },

  data() {
    return {
      familySiteClicked: false,
    }
  },

  methods: {
    initFamilySiteClicked() {
      this.familySiteClicked = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/main';
</style>
